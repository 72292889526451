@import "../../app.scss";
.services {
  background: linear-gradient(180deg, #0c0c1d, #111132);
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .textContainer {
    flex: 1;
    align-self: flex-end;
    display: flex;
    align-items: center;
    gap: 20px;
    @include mobile {
      align-self: center;
      text-align: center;
      flex-direction: column;
    }
    @include tablet {
      align-self: center;
      text-align: center;
      flex-direction: column;
      // padding-top: 30px;
      flex: 0.5;
    }
    p {
      text-align: right;
      font-weight: 200;
      font-size: 20px;
      color: grey;
      @include mobile {
        text-align: center;
      }
      @include tablet {
        text-align: center;
        font-size: 30px;
      }
    }

    hr {
      width: 500px;
      border: none;
      border-top: 0.5px solid gray;
      @include mobile {
        width: 300px;
      }
      @include tablet {
        width: 400px;
      }
    }
  }
  .titleContainer {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include mobile {
      width: 100%;
      padding-top: 10px;
    }
    @include tablet {
      width: 100%;
      padding-top: 30px;
      flex: 2;
    }
    @include desktop {
      justify-content: center;
    }

    .title {
      display: flex;
      align-items: center;
      gap: 50px;
      @include mobile {
        flex-direction: column;
        text-align: center;
        gap: 15px;
      }
      @include tablet {
        flex-direction: column;
        text-align: center;
        gap: 15px;
      }
      @include desktop {
        gap: 25px;
      }
      @include large {
        gap: 25px;
      }

      img {
        width: 300px;
        height: 100px;
        border-radius: 50px;
        object-fit: cover;
        @include mobile {
          width: 200px;
          height: 50px;
        }
        @include tablet {
          width: 300px;
          height: 100px;
        }
        @include large {
          width: 250px;
          height: 80px;
        }
      }
      h1 {
        font-size: 96px;
        font-weight: 100;
        @include mobile {
          font-size: 36px;
        }
        @include tablet {
          font-size: 60px;
        }
        @include desktop {
          font-size: 80px;
        }
        @include large {
          font-size: 60px;
        }
      }
      button {
        width: 300px;
        height: 100px;
        border: none;
        border-radius: 50px;
        background-color: orange;
        font-size: 24px;

        @include mobile {
          font-size: 16px;
          width: 150px;
          height: 50px;
        }
        @include tablet {
          font-size: 16px;
          width: 150px;
          height: 50px;
        }
        @include desktop {
          font-size: 20px;
          width: 200px;
          height: 80px;
        }
        @include large {
          font-size: 20px;
          width: 180px;
          height: 60px;
        }
      }
    }
  }
  .listContainer {
    flex: 2;
    display: flex;
    margin: auto;
    max-width: 1336px;
    @include mobile {
      flex-direction: column;
      width: 100%;
    }
    @include tablet {
      flex-direction: column;
      width: 100%;
      gap: 10px;
      padding-top: 20px;
      justify-content: center;
    }
    @include large {
      padding: 5px 20px;
      justify-content: center;
    }

    .box {
      padding: 50px;
      border: 0.5px solid gray;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 5px;
      @include mobile {
        border: none;
        align-items: center;
        padding: 15px;
        gap: 5px;
      }
      @include tablet {
        border: none;
        align-items: center;
        padding: 7px 15px;
      }
      @include large {
        padding: 40px 20px 30px 20px;
      }

      h1 {
        font-size: 24px;
        color: orange;
        @include large {
          font-size: 20px;
        }
      }

      p {
        font-size: 20px;
        @include mobile {
          display: none;
        }
        @include tablet {
          display: none;
        }
        @include large {
          font-size: 16px;
        }
      }
      button {
        padding: 5px;
        background-color: orange;
        border: none;
        @include mobile {
          display: none;
        }
        @include tablet {
          display: none;
        }
      }
    }
  }
}
