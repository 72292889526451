@import "../../app.scss";

.sideBar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  color: black;

  .bg {
    width: 400px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: white;
    z-index: 2;
    @include mobile {
      width: 300px;
    }
    @include tablet {
      width: 300px;
    }
    .links {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 20px;

      a {
        font-size: 40px;
        @include mobile {
          font-size: 30px;
        }
        @include tablet {
          font-size: 30px;
        }
      }
    }
  }

  button {
    position: fixed;
    top: 25px;
    left: 25px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    cursor: pointer;
    z-index: 2;
  }
}
