@import "../../app.scss";

.cursor {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 0.5px solid white;
  position: fixed;
  z-index: 999;

  @include mobile {
    display: none;
  }
  @include tablet {
    display: none;
  }
  @include desktop {
    display: none;
  }
}
