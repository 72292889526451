@import "../../app.scss";
.parallex {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  z-index: -1;

  h1 {
    font-size: 100px;
    @include mobile {
      font-size: 40px;
      text-align: center;
    }
    @include tablet {
      font-size: 72px;
      text-align: center;
    }
  }
  .mountains {
    background-image: url("../../assets/mountains.png");
    background-size: cover;

    background-position: bottom;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    @include mobile {
      background-size: contain;
      background-repeat: no-repeat;
    }
    @include tablet {
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  .planets {
    background-size: cover;
    background-position: bottom;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -2;
    &.sun {
      background-image: url("../../assets/sun.png");
    }
    &.planet {
      background-image: url("../../assets/planets.png");
    }
    @include mobile {
      background-size: contain;
      background-repeat: no-repeat;
    }
    @include tablet {
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  .stars {
    background-image: url("../../assets/stars.png");
    background-size: cover;

    background-position: bottom;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -3;
  }
}
