@import "../../app.scss";

.navbar {
  height: 100px;
  position: relative;

  .wrapper {
    max-width: 1366px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    z-index: -1;

    @include mobile {
      justify-content: flex-end;
    }
    @include tablet {
      justify-content: flex-end;
    }
    @include desktop {
      justify-content: flex-end;
    }
    @include large {
      justify-content: flex-end;
    }

    span {
      font-weight: bold;
      font-size: 25px;
      @include mobile {
        display: none;
      }
      @include tablet {
        display: none;
      }
      @include desktop {
        display: none;
      }
      @include large {
        display: none;
      }
    }
    .social {
      display: flex;
      gap: 20px;
      @include mobile {
        padding: 20px;
      }
      @include tablet {
        padding: 20px;
      }
      @include desktop {
        padding: 20px;
      }
      @include large {
        padding: 20px;
      }

      img {
        width: 25px;
        height: 25px;
      }
    }
  }
}
