@import "../../app.scss";
.portfolio {
  position: relative;
  .progress {
    position: sticky;
    top: 0;
    left: 0;
    text-align: center;
    font-size: 36px;
    color: orange;
    padding-top: 50px;
    @include mobile {
      padding-top: calc(100vh - 130px);
      font-size: 20px;
    }
    @include tablet {
      padding-top: calc(100vh - 130px);
      font-size: 20px;
    }
    @include large {
      font-size: 26px;
    }
    .progress-bar {
      height: 10px;
      background-color: white;
    }
  }
  .container {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .wrapper {
      max-width: 1336px;
      margin: auto;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 50px;

      @include mobile {
        flex-direction: column;
        gap: 25px;
      }
      @include tablet {
        flex-direction: column;
        gap: 25px;
      }
      @include desktop {
        padding: 20px;
      }
      @include large {
        padding: 25px;
      }

      .imgContainer {
        flex: 1;
        height: 50%;

        @include mobile {
          max-height: 300px;
          width: 100%;
          padding: 5px;
        }
        @include tablet {
          max-height: 500px;
          width: 100%;
          padding: 5px;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 20px;
          @include mobile {
            object-fit: contain;
            border-radius: 5px;
          }
          @include tablet {
            border-radius: 5px;
          }
        }
      }
      .titleContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 30px;
        z-index: 1;
        @include mobile {
          transform: none !important;
          padding: 10px;
          align-items: center;
          text-align: center;
          gap: 15px;
        }
        @include tablet {
          transform: none !important;
          padding: 20px 10px 10px 10px;
          align-items: center;
          text-align: center;
          gap: 15px;
        }

        h1 {
          font-size: 72px;
          line-height: 72px;
          @include mobile {
            font-size: 36px;
            line-height: 36px;
          }
          @include tablet {
            font-size: 42px;
            line-height: 36px;
          }
          @include large {
            font-size: 50px;
            line-height: 50px;
          }
        }
        p {
          color: gray;
          font-size: 25px;
          @include mobile {
            font-size: 16px;
          }
          @include tablet {
            font-size: 22px;
          }
          @include large {
            font-size: 24px;
          }
        }
        a {
          border: none;
          border-radius: 15px;
          width: 200px;
          padding: 10px;
          background-color: orange;
          cursor: pointer;
          font-size: 20px;
          font-weight: 400;
          color: black;
          text-align: center;
          @include mobile {
            font-size: 16px;
            width: 150px;
            z-index: 10;
          }
          @include tablet {
            font-size: 16px;
            width: 150px;
            z-index: 10;
          }
        }
      }
    }
  }
}
